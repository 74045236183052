.title {
  font-family: 'Bryant-Bold';
  color: #736e6d;
  border-left: 8px solid #e51b26;
  text-align: left;
  padding-left: 1rem;
  margin: 1.5rem 0;
  font-weight: bold;
}

.simple-title {
  font: normal normal 900 72px/97px 'Open Sans';
  text-shadow: 0px 3px 10px #000;
  color: #ffffff;
  position: relative;
  top: calc(63% - 1px);
  text-align: center;
}

@media only screen and (max-width: 414px) {
  .simple-title {
    font: normal normal 900 72px 'Open Sans';
    top: calc(4rem - 1px);
    font-size: 27px;
    margin-top: 20px;
  }
}

@media (min-width: 415px) and (max-width: 1024px) {
  .simple-title {
    font: normal normal 900 72px 'Open Sans';
    top: calc(10rem - 1px);
    font-size: 55px;
    margin-bottom: 20px;
  }
}

